import Logo from '../assets/images/SeniorSavingsCroppedLogo.jpg';

export default function setPageTitleAndFavicon(title) {
  if (typeof document !== 'undefined') {
    const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = Logo;
    document.getElementsByTagName('head')[0].appendChild(link);
    document.title = title;
  }
}
