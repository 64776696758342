import React from 'react';
import { Grid } from '@material-ui/core';
import { element, number, oneOfType, string } from 'prop-types';

export default function Box({ children, key = '' }) {
  return (
    <Grid key={key} container justify="center" alignItems="center">
      {children}
    </Grid>
  );
}

Box.propTypes = {
  children: element.isRequired,
  key: oneOfType([string, number]).isRequired,
};
